import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './app.css';

import Hero from './components/Hero';
import Header from './components/Header';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Blog from './components/Blog';
import Contact from './components/Contact';
import Drip from './components/Drip';
import Tools from './components/Tools';
import IndividualBlog from './components/IndividualBlog';
// import NotFound from './components/notfound';


function App() {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const isMobile = window.innerWidth <= 768;


  useEffect(() => {
    // Check if the location has changed
    if (location !== displayLocation) {
      // Apply the fade-out class
      setIsFadingOut(true);

      // After a delay (you can adjust this as needed), remove the fade-out class
      const timeout = setTimeout(() => {
        setIsFadingOut(false);

        // Update the displayLocation
        setDisplayLocation(location);
      }, 3600); // 500ms delay (adjust as needed)

      // Clear the timeout in case the location changes again before the timeout
      return () => clearTimeout(timeout);
    }
  }, [location, displayLocation]);



  return (
    <div className="sitecontainer">
      
      
      <Header isMobile={isMobile} />
      <div className={`content-container ${isFadingOut ? 'fade-out' : 'fade-in'}`}>
      {isFadingOut ? <Drip isMobile={isMobile} /> : ''}
        <Routes location={displayLocation}>
          <Route exact path="/" element={<Hero isMobile={isMobile}/>} />
          <Route path="/about" element={<About isMobile={isMobile}/>} />
          <Route path="/portfolio" element={<Portfolio isMobile={isMobile}/>} />
          <Route path="/blog" element={<Blog isMobile={isMobile}/>} />
          <Route path="/contact" element={<Contact isMobile={isMobile}/>} />
          <Route path="/tools" element={<Tools />} />
          <Route path="/blog/:postId*" element={<IndividualBlog />} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </div>
    </div>
  );

}

export default App;
