import React, { useState } from 'react';
import styles from './BoxShadowGenerator.module.css';

const TextShadowGenerator = () => {
  const [horizontalOffset, setHorizontalOffset] = useState(2);
  const [verticalOffset, setVerticalOffset] = useState(2);
  const [blurRadius, setBlurRadius] = useState(2);
  const [textColor, setTextColor] = useState('#ff0000');
  const [shadowColor, setShadowColor] = useState('#000000');
  const [backgroundColor, setBackgroundColor] = useState('#eeeeee');

  const textShadow = `${horizontalOffset}px ${verticalOffset}px ${blurRadius}px ${shadowColor}`;
  const boxShadow = `0px 0px 0px 0px`;
  const background = `${backgroundColor}`
  const border = `1px solid black`;

  return (
    <div className="text-shadow-generator">
      <h2>CSS Text-shadow Generator</h2>
      <p className={styles.previewbox} style={{ textShadow, color: textColor, boxShadow, background, border }}>
        Sample Text
      </p>
      <div className="controls">
        <label>
          Horizontal Offset:
          <input
            type="range"
            min="-10"
            max="10"
            value={horizontalOffset}
            onChange={(e) => setHorizontalOffset(e.target.value)}
          />
        </label>
        <label>
          Vertical Offset:
          <input
            type="range"
            min="-10"
            max="10"
            value={verticalOffset}
            onChange={(e) => setVerticalOffset(e.target.value)}
          />
        </label>
        <label>
          Blur Radius:
          <input
            type="range"
            min="0"
            max="10"
            value={blurRadius}
            onChange={(e) => setBlurRadius(e.target.value)}
          />
        </label>
        <div className={styles.colors}>
        <label className={styles.color}>
          Text color:
          <input
            type="color"
            value={textColor}
            onChange={(e) => setTextColor(e.target.value)}
          />
        </label>
        <label className={styles.color}>
          Shadow color:
          <input
            type="color"
            value={shadowColor}
            onChange={(e) => setShadowColor(e.target.value)}
          />
        </label>
        <label className={styles.color}>
          Background:
          <input
            type="color"
            value={backgroundColor}
            onChange={(e) => setBackgroundColor(e.target.value)}
          />
        </label>
        </div>
      </div>
      <div className={styles.cssOutput}>
        <textarea value={
          `text-shadow: ${textShadow};
color: ${textColor};`} readOnly />
      </div>
    </div>
  );
};

export default TextShadowGenerator;
