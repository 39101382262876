import React, { useState } from 'react';
import styles from './BoxShadowGenerator.module.css';

const BoxShadowGenerator = () => {
  const [horizontalOffset, setHorizontalOffset] = useState(5);
  const [verticalOffset, setVerticalOffset] = useState(5);
  const [blurRadius, setBlurRadius] = useState(5);
  const [spreadRadius, setSpreadRadius] = useState(5);
  const [color, setColor] = useState('#000000');

  const boxShadow = `${horizontalOffset}px ${verticalOffset}px ${blurRadius}px ${spreadRadius}px ${color}`;

  return (
    <div className="box-shadow-generator">
      <h2>CSS Box-shadow Generator</h2>
      <div className={styles.previewbox} style={{ boxShadow }}></div>
      <div className="controls">
        <label>
          Horizontal Offset:
          <input
            type="range"
            min="-40"
            max="40"
            value={horizontalOffset}
            onChange={(e) => setHorizontalOffset(e.target.value)}
          />
        </label>
        <label>
          Vertical Offset:
          <input
            type="range"
            min="-40"
            max="40"
            value={verticalOffset}
            onChange={(e) => setVerticalOffset(e.target.value)}
          />
        </label>
        <label>
          Blur Radius:
          <input
            type="range"
            min="0"
            max="40"
            value={blurRadius}
            onChange={(e) => setBlurRadius(e.target.value)}
          />
        </label>
        <label>
          Spread Radius:
          <input
            type="range"
            min="0"
            max="40"
            value={spreadRadius}
            onChange={(e) => setSpreadRadius(e.target.value)}
          />
        </label>
        <label>
          Color:
          <input
            type="color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
        </label>
      </div>
      <div className={styles.cssOutput}>
        <textarea value={`box-shadow: ${boxShadow};`} readOnly />
      </div>
    </div>
  );
};

export default BoxShadowGenerator;
