import React from 'react';
import styles from './css/about.module.css';

const About = (props) => {

  if(props.isMobile){
    return (
      <div className={styles.aboutContainer}>
          <div className={styles.aboutMe}>
          <img src="./img/melt-sepia.png" className={styles.aboutImg} alt="Profile of Melt in Sepiatones"></img>
          </div>
        <div className={styles.aboutText}>
              <h3>I'm Melt, a creative designer, writer and marketeer, with a new found interest for IT. Currently working for Qquest, waiting for an exciting secondment position in IT.</h3>
              <p>
              I believe creativity is a secret sauce that transforms works of code into awesome projects. Good thing I know the ingrediënts! I'm currently taking the first few steps into the world of IT. After three years working as a journalist, and four years tackling the world of marketing, I decided to go back into learning. And I've started with Java, Javascript and React.
              </p>
              <p>
                On this website, you'll find various sections that delve into what makes me (and the website) Melt. I enjoy sharing my knowledge, so feel free to shoot me a message if you need my opinion or thoughts on something.
              </p>
            </div>
        </div>

    )
  }
  return (
    <div className={styles.aboutContainer}>
        <div className={styles.left_content}>
          <div className={styles.aboutMe}>
          <img src="./img/melt-sepia.png" className={styles.aboutImg} alt="Profile of Melt in Sepiatones"></img>
          </div>
        </div>
        <div class={styles.right_content}>
        <div className={styles.aboutText}>
              <h3>I'm Melt, a creative designer, writer and marketeer, with a new found interest for IT. Currently working for Qquest, waiting for an exciting secondment position in IT.</h3>
              <p>
              I believe creativity is a secret sauce that transforms works of code into awesome projects. Good thing I know the ingrediënts! I'm currently taking the first few steps into the world of IT. After three years working as a journalist, and four years tackling the world of marketing, I decided to go back into learning. And I've started with Java, Javascript and React.
              </p>
              <p>
                On this website, you'll find various sections that delve into what makes me (and the website) Melt. I enjoy sharing my knowledge, so feel free to shoot me a message if you need my opinion or thoughts on something.
              </p>
            </div>
        </div>
        
            
        
    </div>
  );
};

export default About;
