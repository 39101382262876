import React from 'react';
import styles from './portfoliolightbox.module.css';

const NodeScape = () => {
  return (
    <>
      <h1 className={styles.projectTitle}>NodeScape: A Solution for IT Landscape Management</h1>
      <div className={styles.intro}>I took on the role of Scrummaster and developer in a team at Qquest, to work on the NodeScape project. Nodescape is designed to address a critical internal challenge. During external server audits, we consistently encountered new vulnerabilities—open ports, unknown IP addresses, and potentially unused tools.</div>
      <div className={styles.projectSummary}>

        <ul>
          <li><h2 className={styles.sectionTitle}>The problem </h2>
            At Qquest, many employees initiate and deploy projects on our servers. However, when these employees move on, critical project knowledge often departs with them, leaving us unaware of whether these projects are still in use or if there are associated SLAs.
            NodeScape resolves this issue by providing a comprehensive overview of Qquest's IT landscape. Employees can log in with their company email to save their projects.
            If they leave, NodeScape retains a record of the projects they've worked on and which other processes utilize them. During subsequent audits, we can identify project ownership and decide whether to continue, close, or archive a project in NodeScape.

            <h2 className={styles.sectionTitle}>Key Features of NodeScape</h2>
            <p><b>Complete IT Landscape Overview:</b> NodeScape offers a comprehensive view of Qquest's IT landscape, mapping it on a central platform through domains like applications, servers, and product owners.</p>
            <p><b>Relational Node Representation:</b> NodeScape employs a relational node representation, enabling users to understand the connections and dependencies between various elements in the IT landscape. This aids new team members and employees in familiarizing themselves with Qquest's landscape.</p>
            <p><b>Customizable Filtering:</b> Users can filter and measure nodes across different domains, allowing them to retrieve specific information and analyze defined domains, such as URLs or names and email addresses of product owners. This facilitates targeted analysis and insights into specific areas of the IT landscape.</p>
            <p><b>Marketing Benefits:</b> NodeScape serves marketing purposes for Qquest, offering links to Qquest and serving as a valuable source for testimonials and LinkedIn posts. Other companies can log in with their corporate emails to map their landscapes with minimal Qquest administration.</p>




          </li>
          <li><img src="http://nodescape-manual-test.s3-website.eu-central-1.amazonaws.com/img/NodeScape-logo.png" alt="Logo NodeScape"></img>
            <p><b>Fun fact: I also designed this logo!</b></p><p><a href="http://nodescape-manual-test.s3-website.eu-central-1.amazonaws.com/" className={styles.link}>Take a look at NodeScape.</a></p>
          </li>
        </ul>

        <ul>
          <li><h2 className={styles.sectionTitle}>Learning Objectives</h2>
            <p>
              Before starting work on the NodeScape project, I had not had any experience with Javascript or React. Because NodeScape is primarily built in React and operates on a serverless AWS database, I got to learn these tools pretty quick. My focus has been on the front-end of NodeScape. I've also written several Lambda functions to retrieve data from the database. For NodeScape, we utilized various AWS services, including Lambda, RDS, API Gateway, Cognito, SES, and IAM. We also explored the Amplify library, a valuable tool widely used in IT companies. The knowledge gained in working with these technologies is immensely valuable during interviews.
            </p>
          
          </li>
          <li>
            <div className={styles.projectAchievements}>
            <h2 className={styles.sectionTitle}>Our Achievements So Far</h2>
            <p>
              We've brought NodeScape 1.0 online, but the adventure is far from over. While it's live, we've got a few missing features that are already built in our development version.

              I've taken charge of a significant chunk of the frontend work, rewriting and optimizing the code for efficiency. My colleague conceptualized the branding, and I translated a big part of it into CSS.

              In addition to coding, I've also donned the Scrum master hat, ensuring smooth project management, and I've meticulously documented our project's journey.
            </p>
          </div>
        </li>
        </ul>
        </div>
      

      <div className={styles.projectTasks}>
        <h2 className={styles.sectionTitle}>What Lies Ahead</h2>
        <p>
          Our focus now is to foster seamless communication between the website and the backend. We're primarily enhancing data visualization, making it interactive and editable from the frontend.
        
          On top of that, we're crafting an engaging onboarding tour for first-time users. Think of it as a friendly welcome guide, explaining how our systems function and how users can harness their power.
        
          There's more in store too! We're working on creating view-links, integrating web-based push notifications, enabling users to create and delete data in the database, ensuring GDPR compliance, and a handful of other nifty features.
        </p>
      </div>

      <div className={styles.projectConclusion}>
        <p>
          Our NodeScape journey is a thrilling ride through the world of web development. We've come a long way, but the best is yet to come. Stay tuned for more exciting updates!
        </p>
      </div>
    </>
  )
}

export default NodeScape;