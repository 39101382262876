import React, { useState } from 'react';
import styles from './css/contact.module.css';

const ThankYou = () => {
  const [randomFact, setRandomFact] = useState('');
  const [source, setSource] = useState('');

  const fetchCatFact = () => {
    // Make an API request to retrieve a random cat fact
    fetch('https://meowfacts.herokuapp.com/')
      .then((response) => response.json())
      .then((data) => {
        setRandomFact(data.data);
        setSource("Meowfacts")
      })
      .catch((error) => {
        console.error('Error fetching cat fact:', error);
      });
  };

  const fetchDogFact = async () => {
    try {
      const response = await fetch('https://dogapi.dog/api/v2/facts?limit=1');
      
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      
      const data = await response.json();
      const fact = data.data[0]?.attributes.body || 'No dog facts available';
      
      setRandomFact(fact);
      setSource('dogapi.dog');
    } catch (error) {
      console.error('Error fetching dog fact:', error);
    }
  };

  return (
    <div className={styles.contactContainer}>
      <div className={styles.thanks}>
        <h1 className={styles.heart}>♡</h1>
      <p>Thank you for reaching out! Your message has been sent, and I will reply to it a.s.a.p!</p>
      <p>In the meantime, why don't you check out some of these facts about dogs and cats.</p>
      </div>
      <div className={styles.factButtons}>
        <button className={styles.factsButton} onClick={fetchCatFact}>I want a random cat fact</button>
        <button className={styles.factsButton} onClick={fetchDogFact}>I want a random dog Fact</button>
      </div>

      {randomFact && <div className={styles.fact}><strong>Heres a fact:</strong> {randomFact} <br/><i>Source: {source}</i></div>}
    </div>
  );
};

export default ThankYou;
