import React, { useEffect, useState } from 'react';
import styles from './css/blog.module.css';
import { Helmet } from 'react-helmet-async';

// Function to parse date strings in the format "Month Day, Year"
const parseDate = (dateString) => {
  const [month, day, year] = dateString.split(' ');
  return new Date(`${month} ${day}, ${year}`);
};

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [expandedPosts, setExpandedPosts] = useState([]);


  useEffect(() => {
    // Use require.context to dynamically import JSON files from the folder
    const context = require.context('./posts', false, /\.json$/);

    // Get the keys (file paths) of all JSON files in the folder
    const fileKeys = context.keys();

    // Dynamically import each JSON file and add it to the blogPosts array
    const importedBlogPosts = fileKeys.map((key) => {
      const importedJson = context(key);
      return {
        title: importedJson.title,
        author: importedJson.author,
        date: importedJson.date,
        firstSection: importedJson.content[0].text,
        image: importedJson.image,
        content: importedJson.content,
        breadcrumb: importedJson.breadcrumb,
      };
    });



    // Sort the blog posts by date
    importedBlogPosts.sort((a, b) => parseDate(b.date) - parseDate(a.date));

    // Set the imported and sorted blog posts in the state
    setBlogPosts(importedBlogPosts);
    console.log(importedBlogPosts);
  }, []);

  const togglePostExpansion = (postId) => {
    if (expandedPosts.includes(postId)) {
      setExpandedPosts(expandedPosts.filter(id => id !== postId));
    } else {
      setExpandedPosts([...expandedPosts, postId]);
    }
  };

  const generateShareLink = (filename) => {
    const domain = 'http://www.meltpels.nl';
    return `${domain}/blog/${filename}`;
  };

  // Function to generate a share link for Facebook
  const generateFacebookShareLink = (filename) => {
    const domain = 'http://www.meltpels.nl';
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`${domain}/blog/${filename}`)}`;
  };

  // Function to generate a share link for Twitter
  const generateTwitterShareLink = (filename) => {
    const domain = 'http://www.meltpels.nl';
    const text = encodeURIComponent('Check out this blog post:');
    return `https://twitter.com/intent/tweet?url=${encodeURIComponent(`${domain}/blog/${filename}`)}&text=${text}`;
  };

  // Function to generate a share link for Email
  const generateEmailShareLink = (filename) => {
    const subject = encodeURIComponent('Check out this blog post');
    const body = encodeURIComponent(`I thought you might be interested in this blog post: ${generateShareLink(filename)}`);
    return `mailto:?subject=${subject}&body=${body}`;
  };

  const renderBlogPosts = () => {
    return blogPosts.map((post, index) => (
      <div key={index} className={expandedPosts.includes(index) ? styles.blog_post : styles.blog_post_closed} >
        <img src={post.image} className={expandedPosts.includes(index) ? styles.blog_image : styles.blog_image_closed} alt={post.title} />
        <div className={styles.blog_content}>
          <h2 className={styles.blog_header}>{post.title}</h2>
          <div className={styles.blog_details}>
            <p className={styles.blog_author}>Author: {post.author} | Date: {post.date}</p>
          </div>
          {expandedPosts.includes(index) ? (
            <div className={styles.blog_content_full}>
              {post.content && post.content.length > 0 ? (
                post.content.map((section, sectionIndex) => (

                  <div key={sectionIndex} className={styles.section}>
                    {section.inlineimage && <p className={styles.inlineImage}><img src={section.inlineimage} alt={`${post.title} section`}/></p>}
                    {section.sectiontitle === "Intro" ? <p><b>{section.text}</b></p> :
                      <p><h3>{section.sectiontitle}</h3>{section.text}</p>}
                  </div>

                ))
              ) : (
                <p>No content available</p>
              )}
              <button
                onClick={() => togglePostExpansion(index)}
                className={styles.read_more_button}
              >
                Close blog
              </button>
              <div className={styles.share_buttons}>
                <button onClick={() => window.open(generateFacebookShareLink(post.breadcrumb), '_blank')} className={styles.share_button_fb}>Share on Facebook</button>
                <button onClick={() => window.open(generateTwitterShareLink(post.breadcrumb), '_blank')} className={styles.share_button_x}>Share on Twitter</button>
                <button onClick={() => window.open(generateEmailShareLink(post.breadcrumb), '_blank')} className={styles.share_button_e}>Share via Email</button>
              </div>
            </div>
          ) : (
            <div className={styles.blog_content_excerpt}>
              <p>{post.content[0].text}</p>

              <button
                onClick={() => togglePostExpansion(index)}
                className={styles.read_more_button}
              >
                Read more
              </button>
              <div className={styles.share_buttons}>
                <button onClick={() => window.open(generateFacebookShareLink(post.breadcrumb), '_blank')} className={styles.share_button_fb}>Share on Facebook</button>
                <button onClick={() => window.open(generateTwitterShareLink(post.breadcrumb), '_blank')} className={styles.share_button_x}>Share on Twitter</button>
                <button onClick={() => window.open(generateEmailShareLink(post.breadcrumb), '_blank')} className={styles.share_button_e}>Share via Email</button>
              </div>
            </div>
          )}
        </div>
      </div>
    ));
  };




  return (
    <div className={styles.blog_container}>
      <Helmet>
        <title>Meltpels.nl - My blog</title>
        <meta
          name="description"
          content="My self-published unfiltered thoughts, for you to read!"
        />
        <meta property="og:image"
        content ="/img/melt-sepia.png"
        />
        <meta property="og:title"
        content ="My thoughts written down, for you to read - Melt Pels"
        />
        <meta property="og:description"
        content ="Ever wondered what's going on in my mind? This is your chance to take a peek."
        />
        <meta property="og:type"
        content ="article:section"
        />
      </Helmet>
      <h1 className={styles.blog_title}>Blog</h1>
      {renderBlogPosts()}
      <div className={styles.blog_spacer}></div>
    </div>
  );
};

export default Blog;
