import React from 'react';
import styles from './portfoliolightbox.module.css';

const Keiroze = () => {
  return (
    <>
      <h1 className={styles.projectTitle}>Stichting Keiroze: Volunteering to make a safe space</h1>
      <div className={styles.intro}>
      Stichting Keiroze is a non-profit organization that advocates for the rights and interests of the LGBTQ+ community in Amersfoort, Netherlands. It was founded in 1982, following the Zwarte Roze Zaterdag (Black Pink Saturday), a violent attack on the LGBTQ+ community in Amersfoort.
      As a board member of Keiroze, I help organise a variety of events and activities throughout the year, including the Pride Walk Amersfoort, the Coming Out Festival, and keeping track of the Gaylender. We works to promote LGBTQ+ rights and acceptance in the workplace, schools, and other public spaces.
      </div>
      <div className={styles.projectSummary}>

        <ul>
          <li><h2 className={styles.sectionTitle}>What does Keiroze do?</h2>         
Keiroze is a valuable resource for the LGBTQ+ community in Amersfoort, and it plays an important role in promoting equality and inclusion for all.

Here are some of the specific things that Stichting Keiroze does:

Organizes events and activities for the LGBTQ+ community, such as the Pride Walk Amersfoort, the Coming Out Festival, and the Gaylender.
Works to promote LGBTQ+ rights and acceptance in the workplace, schools, and other public spaces.
Provides support and resources for LGBTQ+ people and their families.
Advocates for LGBTQ+ rights at the local, regional, and national levels.
Stichting Keiroze is a vital part of the LGBTQ+ community in Amersfoort, and it is committed to creating a more inclusive and welcoming society for everyone.
            
          </li>
          <li><img src="https://keiroze.nl/wp-content/uploads/2021/09/Logo_Keiroze-transparant.png" className={styles.img} alt="Logo van Keiroze"></img>
          </li>
        </ul>
        </div>
      

      <div className={styles.projectTasks}>
        <h2 className={styles.sectionTitle}>My involvement</h2>
        <p>
        When I joined Keiroze, they had just lost access to their website. It was hacked due to a faulty plugin in Wordpress, so it was up to me to rebuild it from scratch. I gathered their wishes and learnt that we wanted a calendar so we can keep track of all the events that are organized within Amersfoort.
        The entire website needed content and I had to make it easy for potential board members later to add new posts to the website, without my involvement. So I set out to do just that. Other then that, I've increased the security of the online prescence to change the passwords into OTP's. As a board member, I've organized multiple pubquizzes with an LGBTQ+ theme, helped with organizing a few pride marches and help out wherever I can by designing graphics or coming up with ideas.
        <br/><a href="https@;//www.keiroze.nl/">Visit the website Keiroze.nl for more information.</a>
        </p>
      </div>
    </>
  )
}

export default Keiroze;