import React from 'react';
import styles from './portfoliolightbox.module.css';

const AmersfoortFiets = () => {
  return (
    <>
      <h1 className={styles.projectTitle}>Amersfoort Fiets: A Unique Bicycle Celebrating the City</h1>
      <div className={styles.intro}>The Amersfoort Fiets, a vibrant red steel steed with wide tires and the city map of Amersfoort on its chain guard. If you're ever in Amersfoort, you might have already seen one riding by and wondered: where do people get those? It's one of the odd projects I've worked on as a designer and marketeer. </div>
      <div className={styles.projectSummary}>
        <ul>
          <li>
            <h2 className={styles.sectionTitle}>Limited Edition</h2>
            <p>
              Only one hundred copies of the Amersfoort Fiets were produced, each with its unique number.
              They are crafted by the Amersfoort-based bicycle maker, Broers Fietsreparateurs, but designed by none other than me.
              From the color on the frame, to the design on the chainguard and the unique number on each bike. It's a bike that says: "I am proud to be an Amersfoorter".

            </p>


            <h2 className={styles.sectionTitle}>Building a Community</h2>
            <p>
              I did not expect that the Amersfoort Fiets would create a kind of community. People who own the Amersfoort Fiets often tell me that they wave to each other, as if they belong to a club. It's really nice to see how the bike lives in Amersfoort.
              I remember handing out the first batch of bikes to the people that pre-ordered the bike. <i>That's right, just like a big Game developer, I've created a product people were so excited to have, that they pre-ordered it. The thought of that fills me with joy!</i> The hand-out was an amazing experience.
              We had all the bikes lined, ordered by number. The enthousiasm the people picking up their bike have, them joining us for a drink and a snack and hanging out to meet the other owners, are still one of the best experiences I've had.
            </p>

            <h2 className={styles.sectionTitle}>The Design</h2>
            <p>
              In order to create this design, I had to interview several Amersfoorters on their love for the city. What did they like most about their medieval town? And what would work on a bicycle. One of the frequently mentioned parts of the city, was the innernmost part of the town map. That's shaped like a heart. <i>The heart of Amersfoort</i>. This got a spot on the chainguard. The Onze-Lieve-Vrouwetoren got a spot on the frame below the saddle. And The iconic Koppelpoort got a more prominent spot on the frame's outsides. The color red was chosen for it to stick out. If you hadn't noticed, it's almost the exact same color red as a firetruck. And red is the color that represents Amersfoort as well!
            </p>

          </li>
          <li><img src="/img/amersfoortfiets.png" className={styles.img} alt="De Amersfoortfiets met Jelena Sidzija (Kus uit Amersfoort)"></img></li>
        </ul>
      </div>
    </>
  );
}

// ... The rest of your code for the PortfolioLightbox component and portfolioItems remains the same.

export default AmersfoortFiets;

