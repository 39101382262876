import React from 'react';
import styles from './portfoliolightbox.module.css';

const Indebuurt = () => {
  return (
    <>
      <h1 className={styles.projectTitle}>indebuurt Amersfoort: a local online source of positive news</h1>
      <div className={styles.intro}>I worked as a journalist for indebuurt.nl, a local news website in the Netherlands. Here I wrote articles about the city of Amersfoort, covering a wide range of topics, including local news, events, and culture. My favorite part was writing about the people of Amersfoort, and often wrote stories that highlighted the unique and interesting aspects of the city's community.</div>
      <div className={styles.projectSummary}>

        <ul>
          <li><h2 className={styles.sectionTitle}>What is indebuurt</h2>
            <a href="https://www.indebuurt.nl/Amersfoort" className={styles.link}>indebuurt</a> is a hyperlocal news website that operates in over 40 cities and towns in the Netherlands. The website is run by a team of journalists who live and work in the communities they cover. We focused on publishing a variety of content, including news, features, events, and business listings. The website is known for its focus on positive news and its commitment to building community.
            <h2 className={styles.sectionTitle}>My work as a journalist</h2>
            <p>In the three years working for indebuurt, I've published over 2200 articles. The exampled below are just a small portion of the amazing stories I got to create.</p>
            <p><b><a className={styles.link} href="https://indebuurt.nl/amersfoort/video/topdrukte-bij-tattoolab-mensen-laten-een-bij-tattoeren-voor-het-goede-doel~134916/">A profile of a local business owner</a></b> who uses her business to give back to the community.</p>
            <p><b><a className={styles.link} href="https://indebuurt.nl/amersfoort/amersfoorters/amersfoorter-van-de-week/kanjer-douwe-9-maakt-amersfoort-al-drie-jaar-lang-schoon~137929/">Stories about groups of volunteers</a> </b>who use their time to clean up local areas and parks in Amersfoort.</p>
            <p><b><a className={styles.link} href="https://indebuurt.nl/amersfoort/bedrijvigheid/ondernemer-uitgelicht/amersfoortse-tobias-maakte-al-rond-de-3000-sieraden-een-paar-die-ik-niet-zal-noemen~202615/">Interviews with interesting locals</a></b> who do things such as paining murals, organize festivals, have a spectacular collection of items and much more.</p>
            <p><b><a className={styles.link} href="https://indebuurt.nl/amersfoort/verkiezingen/gratis-met-de-taxi-naar-een-stembureau-in-amersfoort-kan-het~202716/">Reports on the city government</a></b> and their plans to make the town a better place for cyclists and the like.</p>




          </li>
          <li><img src="/img/indebuurt.jpeg" className={styles.img} alt="Melt voor indebuurt.nl"></img>
            
          </li>
        </ul>

        </div>
    </>
  )
}

export default Indebuurt;