import React from 'react';
import styles from './portfoliolightbox.module.css';

const DnD = () => {
  return (
    <>
      <h1 className={styles.projectTitle}>Vivaria: My experience as a Dungeon Master</h1>
      <div className={styles.intro}>Dungeons & Dragons (D&D) is an enthralling tabletop role-playing game that invites players into fantasy realms of adventure and magic. 
      As a Dungeon Master (DM), I relish the role of storyteller and world-builder, crafting exciting narratives and guiding players through epic quests. 
      What draws me to being a DM is the opportunity to challenge players' creativity, teamwork, and problem-solving skills as they shape the story through their choices. 
      D&D is a thrilling journey of shared imagination, where players and DM collaboratively create unforgettable tales. Let me tell you about Vivaria, my first DnD Campaign.</div>
      <div className={styles.projectSummary}>

        <ul>
          <li><h2 className={styles.sectionTitle}>About Vivaria</h2>
          <i>Vivaria, a mystical realm shaped by the ancient goddess Selene, is a world where magic, nature, and spirituality intertwine seamlessly. 
          Its sentient forests, enchanted rivers, and whispering mountains are revered as living entities, each possessing a soul. 
          The influence of four celestial moons, Azurea, Crimsona, Opalia, and Umbra, guides its inhabitants. 
          In this vibrant land, heroes embark on quests to preserve the delicate balance between light and shadow. 
          Diverse cities and villages reflect a rich tapestry of cultures and traditions. 
          Vivaria beckons adventurers to explore its magical wonders and engage in conflicts among animistic communities. 
          Amidst its lush landscapes and mystical allure, Vivaria's destiny awaits those who dare to shape its future.</i>
            
          </li>
          <li><img src="/img/Vivaria2.png" alt="Logo of Vivaria"></img>
          </li>
        </ul>
        </div>
      

      <div className={styles.projectTasks}>
        <h2 className={styles.sectionTitle}>Vivaria Compendium</h2>
        <p>
        <p>The Vivaria Compendium is a guide on the world, that each player gets before playing. 
              It introduces players to a vibrant realm where nature possesses consciousness, guided by the ancient moon goddess Selene. 
              With rich lore, diverse factions, and a compelling prophecy, players can embark on epic quests, uncover mysteries, and shape the fate of Vivaria.
              This compendium contains essential information about the world of Vivaria for Dungeons & Dragons adventures, including its lore, factions, unique flora and fauna, celestial phenomena, and character creation guidelines.</p>
        </p>
      </div>

      <div className={styles.projectConclusion}>
        <p>
          I am currently running three groups of players through the same world in seperate parties. Each story so far is completely different from the others, even though the world is in its base, the same. I can't wait for the next session, to see where the players are going.
        </p>
      </div>
    </>
  )
}

export default DnD;