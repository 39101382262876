import React from 'react';
import styles from './portfoliolightbox.module.css';

const OresOfFortune = () => {
  return (
    <>
      <h1 className={styles.projectTitle}>Ores of Fortune: A Custom Minecraft Server</h1>
      <div className={styles.intro}>Ores of Fortune is a thrilling Minecraft adventure that takes the traditional gameplay to new heights. It's a passion project of mine, where I try to turn vanilla Minecraft into a completely custom experience. I do this with a custom resource pack, that I meticulously crafted while reverse-engineering resource packs from popular servers. 
      Thanks to the resource pack, I can introduce a wealth of new items and mechanics that will immerse the players in an exciting, unique experience.</div>
      <div className={styles.projectSummary}>

        <ul>
          <li>
          <div className={styles.projectTasks}>
        <h2 className={styles.sectionTitle}>Future Features and Mechanics</h2>

          <p>Ores of Fortune redefines the Minecraft experience with a host of exciting features and mechanics. Here are some ideas that are currently in the works:</p>
          
            <li><b>Discover Rare Ores:</b> Unearth rare and mystical ores that promise incredible rewards when you mine them. Each ore has a unique purpose and can be used to craft powerful items.</li>
            <li><b>Custom Enchantments:</b> Delve into the world of enchanting with custom enchantments that add depth and strategy to your gear. Unlock their potential to become a true Minecraft master.</li>
            <li><b>Quests and Challenges:</b> Embark on epic quests and face challenging trials that test your skills and determination. Earn exclusive rewards and become a legend in the world of Ores of Fortune.</li>
            <li><b>Community-Driven:</b> Join a thriving community of players who share your passion for exploration and creativity. Collaborate on massive building projects, engage in epic battles, and forge lasting friendships.</li>
          

      </div>
          </li>
          <li><img src="/img/whetstone.png" alt="Ores of Fortune Screenshot" className={styles.img}></img>
          <h2 className={styles.sectionTitle}>About Ores of Fortune</h2>
          Ores of Fortune transports players to a world where the very essence of Minecraft has been reimagined. Dive into a realm where traditional ores take on new meanings, and hidden treasures await those who dare to explore. The custom resource pack enriches your gameplay with visually stunning textures, bringing life to the world of Ores of Fortune.
          </li>
        </ul>
      </div>

      

      <div className={styles.projectConclusion}>
        <p>
          Ores of Fortune offers an unparalleled Minecraft experience, where every block you mine and every adventure you undertake brings excitement and surprises. As the creator of this unique world, I can't wait to see you explore, thrive, and leave your mark on the ever-evolving Ores of Fortune server. Join us today and embark on a journey like no other in the world of Minecraft!
        </p>
      </div>
    </>
  )
}

export default OresOfFortune;
