import React from 'react';
import styles from './css/portfoliolightbox.module.css';
import NodeScape from './Portfoliopages/nodescape'
import AmersfoortFiets from './Portfoliopages/amersfoortfiets';
import DnD from './Portfoliopages/dnd';
import Keiroze from './Portfoliopages/keiroze';
import Indebuurt from './Portfoliopages/indebuurt';
import OresOfFortune from './Portfoliopages/oresoffortune';


const portfolioItems = {
  NodeScape: {
    title: 'NodeScape',
    description: <NodeScape />,
    imageUrl: '/img/nodescape.jpg',
  },
  Amersfoortfiets: {
    title: 'De Amersfoort Fiets',
    description: <AmersfoortFiets />,
    imageUrl: '/img/amersfoortfiets.jpg',
  },
  DnD: {
    title: 'Dungeons & Dragons',
    description: <DnD />,
    imageUrl: '/img/vivaria.png',
  },
  Keiroze: {
    title: 'Keiroze',
    description: <Keiroze />,
    imageUrl: '/img/keiroze.jpg',
  },
  indebuurt: {
    title: 'Indebuurt',
    description: <Indebuurt />,
    imageUrl: 'https://meltpels.nl/img/fotografie/Amersfoort-Melt_Pels3.jpg',
  },
  GitLab: {
    title: 'GitLab',
    description: 'Take a look at the most recent projects I\'ve worked on, on GitLab.',
    imageUrl: 'https://meltpels.nl/img/fotografie/Amersfoort-Melt_Pels4.jpg',
  },
  OresOfFortune: {
    title: 'Ores of Fortune',
    description: <OresOfFortune />,
    imageUrl: '/img/whetstone.png',
  },
  GetInTouch: {
    title: 'Your project here!',
    description: 'I\'ve only just started my career as an IT-Professional. Are you interested in working together? Get in touch!',
    imageUrl: 'https://images.pexels.com/photos/1174746/pexels-photo-1174746.jpeg',
  },
};



const PortfolioLightbox = ({ identifier, closeLightbox }) => {
  const item = portfolioItems[identifier];

  if (!item) {
    return null; // Return null if the identifier doesn't match any portfolio items
  }

  return (
    <div className={styles.lightbox} onClick={closeLightbox()}>
      <div className={styles.lightboxContent}>
        <img src={item.imageUrl} alt={item.title} className={styles.lightboxImage} />

        <p className={styles.lightboxDescription}>{item.description}</p>
      </div>
    </div>
  );
};

export default PortfolioLightbox;