import React, { useState } from 'react';
import styles from './css/tools.module.css';
import BoxShadowGenerator from './tools/boxshadow';
import CssTextShadowGenerator from './tools/textshadow';
import ButtonGenerator from './tools/button';

const About = () => {
  // Define state to keep track of the active menu item
  const [activeMenuItem, setActiveMenuItem] = useState('whatsThis');

  // Define the content for each menu item
  const menuItems = {
    whatsThis: (
      <div className={styles.whatsThis}>
        This page is under heavy construction. I'm working on creating some cool tools to share with you. But for now, please enjoy the buggy ones you can find on this page.
      </div>
    ),
    cssShadow: (
      <div className={styles.content}>
      <BoxShadowGenerator />
      <CssTextShadowGenerator />
      </div>
    ),
    cssButton: (
      <div className={styles.content}>
      <ButtonGenerator />
      </div>
    ),
    suggestions: (
      <div className={styles.suggestions}>
        Got a suggestion for a tool? Let me know!
      </div>
    ),
  };

  // Function to handle menu item clicks
  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };

  return (
    <div className={styles.aboutContainer}>
      <div className={styles.left_content}>
        {/* Create a menu */}
        <ul className={styles.menu}>
          <li
            className={activeMenuItem === 'whatsThis' ? styles.activeMenuItem : styles.menuItem}
            onClick={() => handleMenuItemClick('whatsThis')}
          >
            Home
          </li>
          <li
            className={activeMenuItem === 'cssShadow' ? styles.activeMenuItem : styles.menuItem}
            onClick={() => handleMenuItemClick('cssShadow')}
          >
            CSS Shadow Generators
          </li>
          <li
            className={activeMenuItem === 'cssButton' ? styles.activeMenuItem : styles.menuItem}
            onClick={() => handleMenuItemClick('cssButton')}
          >
            CSS Button Generator
          </li>
          <li
            className={activeMenuItem === 'suggestions' ? styles.activeMenuItem : styles.menuItem}
            onClick={() => handleMenuItemClick('suggestions')}
          >
            Suggestions
          </li>

        </ul>
      </div>

      <div className={styles.right_content}>
        {/* Render the content based on the active menu item */}
        {menuItems[activeMenuItem]}
      </div>
    </div>
  );
};

export default About;
