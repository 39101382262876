import React, { useState } from 'react';
import styles from './css/contact.module.css';
import emailjs from 'emailjs-com';
import ThankYou from './ThankYou';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [messageSent, setMessageSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Check the honeypot field
    if (formData['honingfield']) {
      // If the honeypot field has a value, it's likely a bot, so reject the submission but dont tell them that.
      setMessageSent(true);
      return;
    }
  
    const form = document.getElementById('contactform');
  
    // Send email using Email.js
    emailjs
      .sendForm('service_h80jp66', 'template_nqbt90p', form, 'kj0olT0fdHf6I6hC8')
      .then((response) => {
        setMessageSent(true);
      })
      .catch((error) => {
        console.error('Email sending failed:', error);
      });
  };

  return (
    <div className={styles.hero}>

      {messageSent ? <ThankYou /> :
        <div className={styles.contactContainer}><h1 className={styles.hero_title}>Contact</h1><p className={styles.hero_description}>Lets have a conversation! Fill out this form to send me a message:</p>
          <form className={styles.contact_form} onSubmit={handleSubmit} id="contactform">
            <div className={styles.form_group}>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your name"
                value={formData.name}
                onChange={handleChange}
                required
                className={styles.balloon}
              />

            </div>
            <div className={styles.form_group}>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Your email address"
                value={formData.email}
                onChange={handleChange}
                required
                className={styles.balloon}
              />

            </div>
            <div className={styles.form_group}>
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                placeholder="What would you like to talk about?"
                value={formData.message}
                onChange={handleChange}
                rows="4"
                required
                className={styles.balloon}
              />


            </div>
            <div className={styles.form_group_hidden}>
              <label htmlFor="honingfield">What is 2+2?</label>
              <input
                type="text"
                id="honingfield"
                name="honingfield"
                value={formData.honingfield}
                onChange={handleChange}
              />
            </div>
            <button type="submit">Send</button>
          </form>
        </div>
      }
    </div>
  );
};

export default Contact;

