import React, { useState } from 'react';
import './css/Drip.css';

const Drip = (props) => {
    const numDrips = props.isMobile ? 5 : 20;
    const initialDripConfigs = Array.from({ length: numDrips }, () => ({
        speed: randomSpeed(),
    }));

    function randomSpeed() {
        return Math.random() * (5 - 1) + 0.5;
    }

    return (
            <div className="dripcontainer">
                {initialDripConfigs.map((config, i) => (
                    <div
                        key={i}
                        className="drip"
                        style={{ '--drip-speed': config.speed, gridColumn: i + 1, '--index': i + 1 }}
                    ></div>
                ))}
            </div>
    );
};

export default Drip;
