import React, { useState } from 'react';
import styles from './css/Header.module.css';
import { Link } from 'react-router-dom';

const Header = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  }

  // Function to uncheck the mobile menu checkbox

  if (props.isMobile) {
    // Render different content for mobile
    return (
      <div className={styles.mobmenuContainer}>
        <div className={styles.mobmenu}>
          <nav className={styles.mainMenu}>
            <ul>
              <li>
                <Link to="/" onClick={toggleMenu} className={styles.a}>Home</Link>
              </li>
              <li>
                <Link to="/about" onClick={toggleMenu} className={styles.a}>About</Link>
              </li>
              <li>
                <Link to="/portfolio" onClick={toggleMenu} className={styles.a}>Portfolio</Link>
              </li>
              <li>
                <Link to="/blog" onClick={toggleMenu} className={styles.a}>Blog</Link>
              </li>
              <li>
                <Link to="/contact" onClick={toggleMenu} className={styles.a}>Contact</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }



  return (
    <div className={styles.header}>
      <h1>Melt</h1>
      <ul>
        <li><Link to="/" className={styles.a}>Home</Link></li>
        <li><Link to="/about" className={styles.a}>About</Link></li>
        <li><Link to="/portfolio" className={styles.a}>Portfolio</Link></li>
        <li><Link to="/tools" className={styles.a}>My Tools</Link></li>
        <li><Link to="/blog" className={styles.a}>Blog</Link></li>
        <li><Link to="/contact" className={styles.a}>Contact</Link></li>

      </ul>
    </div>
  );
};

export default Header;
