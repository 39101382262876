import React, { useState } from 'react';
import styles from './ButtonGenerator.module.css';

const ButtonGenerator = () => {
  const [buttonText, setButtonText] = useState('Click Me');
  const [useGradient, setUseGradient] = useState(false);
  const [gradientType, setGradientType] = useState('linear');
  const [gradientDirection, setGradientDirection] = useState('to right');
  const [gradientColors, setGradientColors] = useState(['#3498db', '#e74c3c']);
  const [buttonTextColor, setButtonTextColor] = useState('#ffffff');
  const [borderRadius, setBorderRadius] = useState(5);
  const [fontSize, setFontSize] = useState(16);
  const [fontWeight, setFontWeight] = useState('normal');
  const [textTransform, setTextTransform] = useState('none');
  const [boxShadow, setBoxShadow] = useState('none');
  const [backgroundColor, setBackgroundColor] = useState('#3498db');

  const buttonStyle = {
    background: useGradient
      ? `linear-gradient(${gradientDirection}, ${gradientColors[0]}, ${gradientColors[1]})`
      : backgroundColor,
    color: buttonTextColor,
    borderRadius: `${borderRadius}px`,
    fontSize: `${fontSize}px`,
    fontWeight,
    textTransform,
    boxShadow,
  };

  return (
    <div className={styles.buttonGenerator}>
      <h2>CSS Button Generator</h2>
      <div className={styles.buttonPreview}>
      <div className={styles.previewButton} style={buttonStyle}>
        {buttonText}
      </div>
      </div>
      <div className="controls">
        <label>
          Button Text:
          <input
            type="text"
            value={buttonText}
            onChange={(e) => setButtonText(e.target.value)}
          />
        </label>
        <label>
          Use Gradient:
          <input
            type="checkbox"
            checked={useGradient}
            onChange={() => setUseGradient(!useGradient)}
            className={styles.gradientCheckbox}
          />
        </label>
        {useGradient ? (
          <div>
            <label>
              Gradient Type:
              <select
                value={gradientType}
                onChange={(e) => setGradientType(e.target.value)}
              >
                <option value="linear">Linear Gradient</option>
                <option value="radial">Radial Gradient</option>
              </select>
            </label>
            <label>
              Gradient Direction:
              <input
                type="text"
                value={gradientDirection}
                onChange={(e) => setGradientDirection(e.target.value)}
              />
            </label>
            <label>
              Gradient Colors:
              <input
                type="color"
                value={gradientColors[0]}
                onChange={(e) => {
                  const newColors = [...gradientColors];
                  newColors[0] = e.target.value;
                  setGradientColors(newColors);
                }}
              />
              <input
                type="color"
                value={gradientColors[1]}
                onChange={(e) => {
                  const newColors = [...gradientColors];
                  newColors[1] = e.target.value;
                  setGradientColors(newColors);
                }}
              />
            </label>
          </div>
        ) : <label>
          <input
                type="color"
                value={backgroundColor}
                onChange={(e) => {
                  setBackgroundColor(e.target.value);
                }}
              />
        </label> }
        <label>
          Text Color:
          <input
            type="color"
            value={buttonTextColor}
            onChange={(e) => setButtonTextColor(e.target.value)}
          />
        </label>
        <label>
          Border Radius:
          <input
            type="range"
            min="0"
            max="20"
            value={borderRadius}
            onChange={(e) => setBorderRadius(e.target.value)}
          />
        </label>
        <label>
          Font Size:
          <input
            type="range"
            min="10"
            max="50"
            value={fontSize}
            onChange={(e) => setFontSize(e.target.value)}
          />
        </label>
        <label>
          Font Weight:
          <select
            value={fontWeight}
            onChange={(e) => setFontWeight(e.target.value)}
          >
            <option value="normal">Normal</option>
            <option value="bold">Bold</option>
          </select>
        </label>
        <label>
          Text Transform:
          <select
            value={textTransform}
            onChange={(e) => setTextTransform(e.target.value)}
          >
            <option value="none">None</option>
            <option value="uppercase">Uppercase</option>
            <option value="lowercase">Lowercase</option>
          </select>
        </label>
        <label>
          Box Shadow:
          <input
            type="text"
            value={boxShadow}
            onChange={(e) => setBoxShadow(e.target.value)}
          />
        </label>
      </div>
      <div className="css-output">
        <textarea
          value={`/* CSS for Button */\n.button {\n  background: ${
            useGradient
              ? `${gradientType}-gradient(${gradientDirection}, ${gradientColors[0]}, ${gradientColors[1]})`
              : gradientColors[0]
          };\n  color: ${buttonTextColor};\n  border-radius: ${borderRadius}px;\n  font-size: ${fontSize}px;\n  font-weight: ${fontWeight};\n  text-transform: ${textTransform};\n  box-shadow: ${boxShadow};\n}`}
          readOnly
        />
      </div>
    </div>
  );
};

export default ButtonGenerator;
