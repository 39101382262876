import React, { useState } from 'react';
import styles from './css/portfolio.module.css';
import Lightbox from './portfoliolightbox.js';

const images = [
  {
    url: '/img/nodescape.jpg',
    title: 'NodeScape',
    excerpt: 'A React tool to create an overview of your IT-landscape.',
    identifier: 'NodeScape',
  },
  {
    url: '/img/amersfoortfiets.jpg',
    title: 'De Amersfoort Fiets',
    excerpt: 'A limited edition bicicle I designed for my hometown, that got produced and sold 100 units.',
    identifier: 'Amersfoortfiets',
  },
  {
    url: '/img/vivaria.png',
    title: 'Dungeons & Dragons',
    excerpt: 'An introduction to Vivaria, a homebrew DnD world, created by me.',
    identifier: 'DnD',
  },
  {
    url: '/img/keiroze.jpg',
    title: 'Keiroze',
    excerpt: 'My volunteering work for for Stichting Keiroze, an organisation for the local LGBTQ-family.',
    identifier: 'Keiroze',
  },
  {
    url: 'https://meltpels.nl/img/fotografie/Amersfoort-Melt_Pels3.jpg',
    title: 'Indebuurt',
    excerpt: 'A collection of articles I published during my time working for DPG Media / indebuurt Amersfoort.',
    identifier: 'indebuurt',
  },
  {
    url: 'https://meltpels.nl/img/fotografie/Amersfoort-Melt_Pels4.jpg',
    title: 'GitLab',
    excerpt: 'Take a look at the most recent projects I\'ve worked on, on GitLab. ',
    identifier: 'GitLab',
  },
  {
    url: '/img/whetstone.png',
    title: 'Ores of Fortune',
    excerpt: 'A Minecraft server I\'ve been working and building on.',
    identifier: 'OresOfFortune',
  },
  {
    url: 'https://images.pexels.com/photos/1174746/pexels-photo-1174746.jpeg',
    title: 'Your project here!',
    excerpt: 'I\'ve only just started my career as an IT-Professional. Are you interested in working together? Get in touch!',
    identifier: 'GetInTouch',
  },
];

const Portfolio = (props) => {
  const [lightbox, setLightbox] = useState(null);

  const openLightbox = (identifier) => {
    setLightbox(identifier);
    console.log("Opening lightbox");
  };

  const closeLightbox = () => {
    setLightbox(null);
    console.log("Closing lightbox");
  }

  const goToGitLab = () => {
    window.location = "https://gitlab.com/MeltPels"
  }

  return (
    <>
      {lightbox && <Lightbox identifier={lightbox} closeLightbox={() => closeLightbox}/>}
      <div className={styles.portfolio}>
        <div className={styles.imageGrid}>
          {images.map((image, index) => (
            <div key={index} className={styles.imageContainer}>
              <div
                className={styles.portfolioItem}
                onClick={(image.title === 'GitLab') ? () => goToGitLab() : () => openLightbox(image.identifier) }
                style={{ backgroundImage: `url(${image.url})` }} // Set the background image
              >

                <div className={styles.overlay}></div>
                <h2 className={styles.imageTitle}>{image.title}</h2>
                <p className={styles.imageExcerpt}>{image.excerpt}</p>
              </div>
            </div>
          ))}
        </div>
        
      </div>
    </>
  )
};

export default Portfolio;
