import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './css/IndividualBlog.module.css'; // You may need to create a separate CSS module
import { Helmet } from 'react-helmet-async';

const IndividualBlog = () => {
  const { postId } = useParams(); // Get the postId from the URL
  const [blogData, setBlogData] = useState(null); // State to store blog data

  useEffect(() => {
    const context = require.context('./posts', false, /\.json$/);
    const fileKeys = context.keys();

    // Find the specific file based on postId
    const specificBlogFileKey = fileKeys.find((key) => key.endsWith(`/${postId}.json`));
    console.log(postId);
    if (specificBlogFileKey) {
      const importedJson = context(specificBlogFileKey);

      // Set the blog data in state
      setBlogData({
        title: importedJson.title,
        author: importedJson.author,
        date: importedJson.date,
        firstSection: importedJson.content[0].text,
        image: importedJson.image,
        content: importedJson.content,
        imageUrl: importedJson.image, // Added imageUrl
      });
    }
  }, [postId]);

  const truncateText = (text) => {
    if (text.length > 62) {
      return text.slice(0, 62) + '...';
    }
    return text;
  };

  const parseDate = (dateString) => {
    // Create a Date object from the input date string
    const dateObject = new Date(dateString);
  
    // Check if the dateObject is valid
    if (isNaN(dateObject)) {
      console.error('Invalid date format:', dateString);
      return null;
    }
  
    // Format the date as ISO 8601
    const iso8601Date = dateObject.toISOString();
  
    return iso8601Date;
  };

  const truncatedDescription = blogData ? truncateText(blogData.firstSection) : '';

  // Render the blog post content here
  return (
    <div className={styles.blogContainer}>
      {blogData &&
        <Helmet>
          <title>Meltpels.nl</title>
          <meta 
            name="title"
            property="og:title"
            content={blogData.title + " - Meltpels.nl"}
            />
          <meta
            name="description"
            content={blogData.firstSection}
          />
          <meta 
            name="image"
            property="og:image"
            content={blogData.imageUrl}
          />
          <meta 
            name="description"
            property="og:description"
            content={truncatedDescription}
          />
          <meta 
            property="og:type"
            content="article:section"
          />
          <meta 
            name="url"
            property="og:url"
            content={"https://www.meltpels.nl/blog/" + postId}
          />
          <meta 
            name="publish_date"
            property="article:published_time" 
            content="article" />
          <meta property="og:updated_time" content={parseDate(blogData.date)} />
          <meta 
            name="author"
            content="Melt Pels"
            />
        </Helmet>
      }
      {blogData ? (
        <div className={styles.BlogSection}>
          <div className={styles.bloghero} style={{ backgroundImage: `url(${blogData.image})` }}>
            <div className={styles.blogGlass}>
              <h1 className={styles.blogTitle}><b>{blogData.title}</b></h1>
              <div className={styles.blogDetails}>
                <p className={styles.blogAuthor}>By: {blogData.author} | Published on: {blogData.date}</p>
              </div>
            </div>
          </div>

          {blogData.content.map((section, sectionIndex) => (
            <div key={sectionIndex} className={styles.blogSection}>

              {section.sectiontitle === "Intro" ? (
                <div className={styles.blogIntro}><p >{section.text}</p></div>
              ) : (
                section.inlineimage != null ? (
                  <div className={styles.sectionWithImage}>
                    <img src={section.inlineimage} alt="" />
                    <h3>{section.sectiontitle}</h3>
                    <p>{section.text}</p>
                  </div>) : (
                  <div className={styles.section}>
                    <h3>{section.sectiontitle}</h3>
                    <p>{section.text}</p>
                  </div>)

              )}
            </div>
          ))}







        </div>
      ) : (
        <div className={styles.loading}>Loading...</div>
      )}
    </div>
  );
};

export default IndividualBlog;
