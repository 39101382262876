import React from 'react';
import styles from './css/content.module.css'; 

const Hero = () => {

  const waves = () => {
    return <div className={styles.waves}><svg width="100%" height="100%" id="svg" viewBox="0 0 1440 690" xmlns="http://www.w3.org/2000/svg"
      class="transition duration-300 ease-in-out delay-150"><defs>
        <linearGradient id="gradient" x1="0%" y1="50%" x2="100%" y2="50%"><stop offset="5%" stop-color="#F78DA7"></stop>
          <stop offset="95%" stop-color="#8ED1FC"></stop>
        </linearGradient></defs>
      <path d="M 0,700 C 0,700 0,233 0,233 C 118.92857142857142,214.25 237.85714285714283,195.5 356,199 C 474.14285714285717,202.5 591.5,228.25 707,221 C 822.5,213.75 936.1428571428571,173.5 1058,170 C 1179.857142857143,166.5 1309.9285714285716,199.75 1440,233 C 1440,233 1440,700 1440,700 Z" stroke="none" stroke-width="0" fill="url(#gradient)" fill-opacity="0.53" class="transition-all duration-300 ease-in-out delay-150 path-0"></path>
      <defs>
        <linearGradient id="gradient" x1="0%" y1="50%" x2="100%" y2="50%">
          <stop offset="5%" stop-color="#F78DA7"></stop>
          <stop offset="95%" stop-color="#8ED1FC"></stop>
        </linearGradient></defs>
      <path d="M 0,700 C 0,700 0,466 0,466 C 159.39285714285714,446.9642857142857 318.7857142857143,427.92857142857144 414,440 C 509.2142857142857,452.07142857142856 540.25,495.25 661,504 C 781.75,512.75 992.2142857142858,487.07142857142856 1137,475 C 1281.7857142857142,462.92857142857144 1360.892857142857,464.4642857142857 1440,466 C 1440,466 1440,700 1440,700 Z" stroke="none" stroke-width="0" fill="url(#gradient)" fill-opacity="1" class="transition-all duration-300 ease-in-out delay-150 path-1"></path>
    </svg></div>;
  }

  return (
    <div className={styles.hero}>
      <div className={styles.intro}>
        <div className={styles.profileimage}><img className={styles.profile} src="../img/Melt-profiel-rond.png" alt="Profile of Melt, round"></img></div>

        <h1 className={styles.hero_title}>Hello, I'm Melt</h1>
        <p className={styles.hero_description}>React Developer | DevOps Engineer | Java Programmer</p>
        <div className={styles.Socials}>
  <a href="https://www.instagram.com/meltpels/" target="_blank" rel="noopener noreferrer" className={styles.socialButton}>
    LinkedIn
  </a>
  <a href="https://www.gitlab.com/MeltPels" target="_blank" rel="noopener noreferrer" className={styles.socialButton}>
    GitLab
  </a>
</div>
      </div>
      {waves()}
    </div>
  );
};

export default Hero;
